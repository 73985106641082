import toast from "react-hot-toast";
import API_URLS from "services/endPoints";
import client from "utils/ApiClient";

/* eslint-disable react/function-component-definition */
function useEmailActions() {
  const addRemoveFlag = async (payload: any) => {
    const response: any = await client.post(
      `${API_URLS.action.addRemoveFlag}`,
      payload,
    );
    return response;
  };

  const bulkAddRemoveFlag = async (payload: any) => {
    const response: any = await client.post(
      `${API_URLS.action.bulkAddRemoveFlag}`,
      payload,
    );
    return response;
  };

  const addFolder = async (payload: any) => {
    const response: any = await client.post(
      `${API_URLS.action.addFolder}`,
      payload,
    );
    toast("add successfully");

    return response;
  };
  const removeFolder = async (payload: any) => {
    const response: any = await client.post(
      `${API_URLS.action.removeFolder}`,
      payload,
    );
    toast("remove successfully");

    return response;
  };
  const moveFolder = async (payload: any) => {
    const response: any = await client.post(
      `${API_URLS.action.moveFolder}`,
      payload,
    );
    toast("move successfully");

    return response;
  };
  const sendEmail = async (payload: any) => {
    const response: any = await client.post(
      `${API_URLS.action.sendEmail}`,
      payload,
    );
    toast("email send successfully");
    return response;
  };

  const loginImap = async (payload: any) => {
    const response: any = await client.post(
      `${API_URLS.action.loginImap}`,
      payload,
    );
    return response;
  };
  return {
    removeFolder,
    addRemoveFlag,
    addFolder,
    moveFolder,
    sendEmail,
    loginImap,
    bulkAddRemoveFlag,
  };
}

export default useEmailActions;
