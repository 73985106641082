import { AttachFile, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import FileList from "components/Attachment/FileList";
import Slider from "components/Slider";
import useEmailActions from "hook/useEmailActions";
import { MuiChipsInput } from "mui-chips-input";
import { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useSocket } from "SocketContext";
import "./composeDialog.css";

export default function ComposeDialog() {
  const [isSending, setIsSending] = useState(false);
  const [to, setTo] = useState([]);
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [isCC, setIsCC] = useState(false);
  const [isBCC, setIsBCC] = useState(false);

  const [cc, setCC] = useState([]);
  const [bcc, setBCC] = useState([]);

  const [attachments, setAttachments] = useState<any>([]);

  const { sendEmail } = useEmailActions();
  const { isSendBoxOpen, setSendBoxOpen, defaultEmail } = useSocket();

  const handleAttachmentChange = (event: any) => {
    const selectedFile = event.target.files;
    setAttachments(selectedFile);
  };

  const handleSendEmail = async () => {
    setIsSending(true);
    try {
      const data = new FormData();
      data.append("originalMessageId", "");
      data.append("subject", subject);
      data.append("contend", content);
      data.append("from", defaultEmail.user);
      data.append("user", defaultEmail.user);
      data.append("password", defaultEmail.password);
      data.append("smtp", defaultEmail.smtp);
      if (bcc.length !== 0) {
        data.append("bcc", `${bcc.toString()}`);
      }

      if (cc.length !== 0) {
        data.append("cc", cc.toString());
      }

      if (to.length !== 0) {
        data.append("to", to.toString());
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < attachments.length; i++) {
        data.append("attachments", attachments[i]);
      }
      await sendEmail(data);
      setIsSending(false);
      setSendBoxOpen(false);
      setTo([]);
      setSubject("");
      setContent("");
      setIsCC(false);
      setIsBCC(false);
      setAttachments([]);
    } catch (error) {
      console.error("Failed to send email:", error);
      setIsSending(false);
    }
  };

  return (
    <Slider open={isSendBoxOpen}>
      <Box
        sx={{
          backgroundColor: "#f2f6fc",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "5px",
        }}
      >
        <Typography>Compose Email</Typography>
        <Box>
          <IconButton onClick={() => setSendBoxOpen(false)}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Box>

      <Box sx={{ padding: "20px", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "95%",
          }}
        >
          <MuiChipsInput
            clearInputOnBlur
            label="To"
            size="small"
            sx={{ width: "100%" }}
            value={to}
            variant="standard"
            onChange={(newValue: any) => {
              setTo(newValue);
            }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "5%",
            }}
          >
            <Button
              size="small"
              onClick={() => {
                if (isCC) {
                  setIsCC(false);
                  setCC([]);
                }
                setIsCC(true);
                return null;
              }}
            >
              Cc
            </Button>
            <Button
              size="small"
              onClick={() => {
                if (isBCC) {
                  setIsBCC(false);
                  setBCC([]);
                  return null;
                }
                setIsBCC(true);
                return null;
              }}
            >
              BCC
            </Button>
          </Box>
        </Box>

        {isCC && (
          <MuiChipsInput
            clearInputOnBlur
            label="CC"
            size="small"
            sx={{ width: "100%" }}
            value={cc}
            variant="standard"
            onChange={(newValue: any) => {
              setCC(newValue);
            }}
          />
        )}
        {isBCC && (
          <MuiChipsInput
            clearInputOnBlur
            label="BCC"
            size="small"
            sx={{ width: "100%" }}
            value={bcc}
            variant="standard"
            onChange={(newValue: any) => {
              setBCC(newValue);
            }}
          />
        )}
        <TextField
          label="Subject"
          size="small"
          sx={{ fontSize: "12px", marginBottom: "10px" }}
          value={subject}
          variant="standard"
          onChange={(event) => setSubject(event.target.value)}
        />

        <Box
          sx={{ display: "flex", marginBottom: "10px", alignItems: "center" }}
        >
          <IconButton color="primary" component="label">
            <input
              hidden
              multiple
              type="file"
              onChange={handleAttachmentChange}
            />
            <AttachFile fontSize="medium" />
          </IconButton>
          <Button
            disabled={isSending}
            size="small"
            sx={{ marginLeft: "auto" }}
            variant="contained"
            onClick={handleSendEmail}
          >
            {isSending ? <CircularProgress size={12} /> : "Send"}
          </Button>
        </Box>
        <FileList attachments={attachments} setAttachments={setAttachments} />

        <ReactQuill
          style={{ minHeight: 400, paddingBottom: "100px" }}
          theme="snow"
          value={content}
          onChange={setContent}
        />
      </Box>
    </Slider>
  );
}
